import React, { useEffect } from "react"

export const BBBLogo = ({ addScript }) => {
  useEffect(() => {
    if (addScript) {
      const bbbprotocol =
        "https:" === document.location.protocol ? "https://" : "http://"
      const s = document.createElement("script")
      s.src =
        bbbprotocol +
        "seal-sanjose.bbb.org" +
        unescape("%2Flogo%2Ffloat-direct-buyers-1293827.js")
      s.type = "text/javascript"
      s.async = true
      let st = document.getElementsByTagName("script")
      st = st[st.length - 1]
      const pt = st.parentNode
      pt.insertBefore(s, pt.nextSibling)
    }
  }, [addScript])

  return (
    <div id="bbbfloatseal" style={{ position: "relative" }}>
      <div id="bbbfloatcaption" style={{ display: "none" }}>
        <div id="bbbbiztop">
          <div id="bbbbizname" style={{ color: "#005a78" }}>
            Direct Buyers, LLC
          </div>
          <div id="bbbfloatclose">&#10006;</div>
        </div>
        <div style={{ float: "left" }}>
          <img
            id="bbbbizimg"
            src="https://seal-sanjose.bbb.org/logo/fshzbul/direct-buyers-1293827.png"
            alt=""
          />
          <div id="bbbbizrating" style={{ color: "#005a78" }}>
            A+
          </div>
          <div id="bbbbizscale">On a scale of A+ to F</div>
        </div>
        <div id="bbbbizmiddle">
          <p>Reviewed, Evaluated and Accredited</p>
          <p>Meets All 26 Standards of Accreditation</p>
          <p>BBB Accredited since 12/21/2020</p>
        </div>
        <p id="bbbbizbottom1">
          <a
            href="https://www.bbb.org/us/ca/glendale/profile/real-estate-services/direct-buyers-llc-1216-1293827#bbblogo"
            style={{ font: "bold 12px Helvetica", color: "#005a78" }}
          >
            Click here for BBB Business Profile Report on Direct Buyers, LLC
          </a>
        </p>
        <p id="bbbbizbottom2">
          BBB Accredited Verified on: <i className="bbbfloatdate" />
        </p>
        <p id="bbbbizbottom3">
          BBB Rating as of: <i className="bbbfloatdate" />
        </p>
      </div>
      <a
        id="bbblink2"
        href="https://www.bbb.org/us/ca/glendale/profile/real-estate-services/direct-buyers-llc-1216-1293827#bbblogo"
        title="Verify Direct Buyers, LLC"
        style={{
          display: "block",
          // position: "fixed",
          overflow: "hidden",
          margin: "0px",
          padding: "0px",
          // bottom: "10px",
          // right: "10px",
        }}
      >
        <img
          style={{ padding: "0px", border: "none" }}
          id="bbbfloatlink"
          src="https://seal-sanjose.bbb.org/logo/fshzbul/direct-buyers-1293827.png"
          alt="Verify Direct Buyers, LLC"
        />
      </a>
    </div>
  )
}
