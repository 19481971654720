import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import axios from "axios"
import mq from "../../../styles/media-query"
import { UserContext } from "../../user/user"

const API_URL = process.env.GATSBY_SES_API_URL

const Wrapper = styled.div`
  display: flex; // flex;
  flex-direction: column;
  ${mq.sm`flex-direction: row`};
`

const Input = styled.input`
  border-radius: 5px;
  border: 2px solid rgb(238, 238, 238);
  padding: 12px 20px;
  font-size: 16px;
  color: var(--textOverLight);
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  width: 320px;

  ${mq.sm`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    border-right: 0;
    width: 380px;
   `};
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid rgb(238, 238, 238);
  border-left: 2px solid rgb(238, 238, 238);
  padding: 12px 20px;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: var(--brandPrimary);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease-in-out;
  font-weight: 700;

  ${mq.sm`
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `};

  &:hover {
    background-color: #ffcc00;
    color: #000000;
  }

  &[disabled] {
    background-color: var(--lightGrey);

    &:hover {
      background-color: var(--lightGrey);
      color: #ffffff;
    }
  }
`

export default function AddressForm() {
  const { place, updatePlace } = useContext(UserContext)
  const [query, setQuery] = useState("")
  const [addressValidity, setAddressValidity] = useState(false)
  const autoCompleteRef = useRef(null)
  const autoComplete = useRef(null)

  const getAddressComponentValue = (components, name) => {
    const componentItem = components.filter(component =>
      component.types.includes(name)
    )
    if (Array.isArray(componentItem) && componentItem.length === 1) {
      return componentItem[0].long_name
    }
  }

  const handlePlaceSelect = useCallback(() => {
    const addressObject = autoComplete.current.getPlace()
    const updatedQuery = addressObject.formatted_address
    let components = addressObject.address_components

    // no value, bail
    if (!updatedQuery) {
      // console.log("ERROR :: no query")
      return
    }

    // not california, bail
    let state = getAddressComponentValue(
      components,
      "administrative_area_level_1"
    )
    if (state !== "California") {
      // console.log("ERROR :: not California")
      return
    }

    // no house or street number, bail
    const streetNumber = getAddressComponentValue(components, "street_number")
    if (!streetNumber) {
      // console.log("ERROR :: no street number")
      return
    }

    // if the form value doesn't match the value, bail
    if (updatedQuery !== addressObject.formatted_address) {
      // console.log(`ERROR :: query invalid`)
      return
    }

    updatePlace(addressObject)
    setQuery(updatedQuery)
    setAddressValidity(true)

    const url = `${API_URL}/email/send/lead`
    let data = { address: updatedQuery }
    try {
      axios.post(url, data)
    } catch (err) {
      console.error(err)
    }
  }, [updatePlace])

  useEffect(() => {
    autoComplete.current = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    )
    autoComplete.current.setFields(["address_components", "formatted_address"])
    autoComplete.current.addListener("place_changed", handlePlaceSelect)
    return () => {
      window.google.maps.event.clearInstanceListeners(autoComplete.current)
    }
  }, [handlePlaceSelect])

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-376971454/_OHCCJqam9cCEL7B4LMB",
      value: 1.0,
      currency: "USD",
      event_callback: callback,
    })
    return false
  }

  return (
    <Wrapper>
      <Input
        value={query}
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder={`Enter your address: ${query}`}
      />
      <Button
        disabled={!addressValidity || query !== place.formatted_address}
        onClick={() => {
          if (place.formatted_address) {
            gtag_report_conversion()
            navigate("/get-a-cash-offer")
          }
        }}
      >
        Get My Cash Offer
      </Button>
    </Wrapper>
  )
}
