import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Hider = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => (props.menuOpen ? 0 : "100%")};
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transition: all 0.25s ease-out;
  transition-delay: ${props => (props.menuOpen ? ".1s" : 0)};
  z-index: 3;
`

const Menu = styled.nav`
  height: 100%;
  width: 100%;
  background: var(--brandPrimary);
  overflow: auto;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`

const NavItem = styled(Link)`
  display: inline-block;
  padding: 20px;
  background: var(--brandSecondary);
  width: 100%;
  margin: 1px 0;
  color: var(--white);
  &:hover {
    text-decoration: none;
    color: var(--white);
    background-color: var(--brandSecondary);
  }
`

export const MobileMenu = ({ menuOpen }) => {
  return (
    <>
      <Hider menuOpen={menuOpen} />
      <Menu>
        <NavItem to="/">Our Process</NavItem>
        <NavItem to="/where-we-buy">Where We Buy</NavItem>
        <NavItem to="/frequently-asked-questions">
          Frequently Asked Questions
        </NavItem>
        <NavItem to="/about-us">About Us</NavItem>
        <NavItem to="/reviews">Reviews</NavItem>
        <NavItem to="/get-a-cash-offer">Get a Cash Offer</NavItem>
      </Menu>
    </>
  )
}
