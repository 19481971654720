import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import mq from "../../styles/media-query"
import { Burger } from "./burger"
import { BBBLogo } from "../bbb/bbb-logo"
// import { FaPhoneAlt } from "react-icons/fa";
const logo = require("../../assets/img/logo.gif")

const Header = styled.header`
  background-color: var(--white);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  // text-align: right;
  // height: 80px;

  ${mq.sm`
    // height: 110px;
  `}

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    padding: 0.6rem 2rem 0rem;
    ${mq.md`
        padding-top: 1.6rem;
        padding-bottom: 1rem;
    `}
  }
`

const Logo = styled.span`
  background: transparent url(${logo}) 50% 50% no-repeat;
  width: 180px;
  height: 58px;
  background-size: contain;
  display: inline-block;
  text-indent: -9999em;
  overflow: hidden;

  // position: absolute;
  // left: 15px;
  // top: 10px;

  ${mq.md`
    width: 320px;
    height: 80px;
    // background-size: 221px 80px;
    // left: 15px;
    // top: 15px;
  `};
`

const ContactRow = styled.nav`
  display: none;
  // position: absolute;
  // top: 15px;
  // right: 0;
  line-height: 1;

  ${mq.md`
    display: inline-flex;
  `}
`

const PhoneNumber = styled.a`
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  padding: 10px 10px 6px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid transparent;
  background: var(--brandPrimary);
  color: #fff;

  i {
    transform: rotate(90deg);
    margin-top: -3px;
    margin-right: 8px;
    &::before {
      line-height: 1;
    }
  }

  &:hover {
    color: var(--white);
    //  background: var(--white);
    text-decoration: none;
    //  border-color: var(--brandPrimary);
  }
`

const NavigationRow = styled.nav`
  display: none;
  margin-top: 2rem;
  // position: absolute;
  // top: 60px;
  // right: 0;
  > * + * {
    margin-left: 15px;

    ${mq.md`
        margin-left: 30px;
  `};
  }

  ${mq.md`
    display: inline-flex;
  `};
`

const NavItem = styled(Link)`
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--textOverLight);
  transition: all 0.2s ease-in-out;

  &.active-link {
    color: var(--brandPrimary);
  }

  ${mq.md`
     font-size: 1rem;
  `};

  &:hover {
    color: var(--brandPrimary);
    text-decoration: none;
  }
`

const BBBLogoWrapper = styled.div`
  display: block;
  position: relative;
  margin-right: 1em;
`

// const TelIcon = styled(FaPhoneAlt)`
//   position: relative;
//   bottom: 4px;
//   margin-right: 0.6rem;
//   line-height: 1;
//
// `;

const MobileMenu = styled.div`
  display: inline-block;
  position: absolute;
  top: calc(50% - 1rem);
  right: 15px;

  ${mq.md`
    display: none;
  `}
`

const DesktopRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
`

export default function Navigation(props) {
  const { mobileMenuOpen, setMobileMenuOpen } = props

  return (
    <Header>
      <div className="container-fluid">
        <MobileMenu>
          <Burger
            open={mobileMenuOpen}
            setOpen={() => {
              setMobileMenuOpen(!mobileMenuOpen)
            }}
          />
        </MobileMenu>
        <Link to="/" title="Homepage">
          <Logo />
        </Link>
        <DesktopRow>
          <ContactRow>
            <BBBLogoWrapper>
              <BBBLogo />
            </BBBLogoWrapper>
            <PhoneNumber href="tel:+18442427355">
              {/*<TelIcon color="#fff" size={26}/>*/}
              <i className="fas fa-phone" />
              <span>844-242-SELL</span>
            </PhoneNumber>
          </ContactRow>
          <NavigationRow>
            <div>
              <NavItem activeClassName="active-link" to="/">
                Our Process
              </NavItem>
            </div>
            <div>
              <NavItem activeClassName="active-link" to="/where-we-buy">
                Where We Buy
              </NavItem>
            </div>
            <div>
              <NavItem
                activeClassName="active-link"
                to="/frequently-asked-questions"
              >
                FAQs
              </NavItem>
            </div>
            <div>
              <NavItem activeClassName="active-link" to="/about-us">
                About Us
              </NavItem>
            </div>
            <div>
              <NavItem activeClassName="active-link" to="/reviews">
                Reviews
              </NavItem>
            </div>
            <div>
              <NavItem activeClassName="active-link" to="/get-a-cash-offer">
                Get a Cash Offer
              </NavItem>
            </div>
          </NavigationRow>
        </DesktopRow>
      </div>
    </Header>
  )
}
