import { css } from "styled-components";

const sizes = {
    xl: 1530,
    lg: 1200,
    md: 992,
    sm: 768,
    xs: 576,
    base: 16
};

export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / sizes.base}em) {
      ${css(...args)}
    }
  `;
    return acc
}, {});

export const Print = (...args) => css`
  @media print {
      ${css(...args)}
    }
`;