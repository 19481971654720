import React from "react";
import styled from "styled-components";

const TelephoneStyle = styled.a`
  color: unset;
  font-style: unset;
  text-decoration: unset;
  
  &:hover {
    text-decoration: underline;
    color: unset;
    cursor: pointer;
  }
`;

export default function Telephone() {
    return (
        <TelephoneStyle href="tel:18442427355">1-844-242-SELL</TelephoneStyle>
    )
}
