import React from "react"
import styled from "styled-components"
import mq from "../../styles/media-query"
import { FiInstagram } from "react-icons/fi"
import { Link } from "gatsby"
import Telephone from "../telephone/telephone"
import { BBBLogo } from "../bbb/bbb-logo"

const BBBLogoWrapper = styled.div`
  display: inline-block;
`

const FooterFrame = styled.div`
  position: relative;
  background: var(--brandPrimary);
  color: #fff;

  margin-top: 4.8rem;

  .container {
    justify-content: center; // space-between;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    ${mq.md`
                flex-direction: row;
                margin-top: 0;
            `}
  }

  ul {
    margin: 0;
    ${mq.md`
            display: flex;
            align-items: center;
        `}
  }

  ul,
  .container {
    ${mq.md`
            display: flex;
        `}
  }

  li + li {
    ${mq.md`
        margin-left: 0.8rem;
        `}
  }

  a {
    color: var(--white);
    transition: all 0.4s;

    &:hover {
      // background: var(--white);
      // color: var(--brandSecondary);
      text-decoration: none;
    }
  }
`

const DivSet = styled.ul`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  li + li {
    margin-top: 1rem;

    ${mq.md`
            // margin-left: 0.6rem;
            margin-top: 0;
            &:before {
                display: inline-block;
                content: "\\00B7";
                margin-right: 0.6rem;
            }
        `}
  }
`

const Icons = styled.ul`
  display: none !important; // flex;
  font-size: 24px;
  margin-top: 2rem;
  ${mq.md`
        margin-top: 0;
    `}
  a {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  li + li {
    margin-left: 1rem;
  }
`

const BotFrame = styled.div`
  background: var(--brandSecondary);
  color: var(--lightGrey);
  font-size: 0.8rem;
  text-align: center;
  padding: 12px;
  ${mq.md`
            padding: 20px;
            font-size: 0.9rem;
        `}
`

export default function Footer() {
  return (
    <FooterFrame>
      <div className={`container`}>
        <DivSet>
          <li>
            <Telephone />
          </li>
          <li>
            <a
              href={`https://www.instagram.com/thedirectbuyers/`}
              title="Instagram"
            >
              @thedirectbuyers <FiInstagram />
            </a>
          </li>
          <li>
            <BBBLogoWrapper>
              <BBBLogo addScript={false} />
            </BBBLogoWrapper>
          </li>
        </DivSet>
        <Icons>
          <li>
            <a
              href={`https://www.facebook.com/thedirectbuyers`}
              rel="noreferrer"
              aria-label="Follow Us on Facebook"
              title="Follow Us on Facebook"
              target="_blank"
            >
              <i className={`fab fa-facebook-f`} />
            </a>
          </li>
          <li>
            <a
              href={`https://www.instagram.com/thedirectbuyers`}
              rel="noreferrer"
              aria-label="Follow Us on Twitter"
              title="Follow Us on Twitter"
              target="_blank"
            >
              <i className={`fab fa-instagram`} />
            </a>
          </li>
        </Icons>
      </div>
      <BotFrame>
        Southern California’s #1 Home Buyers
        <br />
        <Link to="/privacy-policy" title="Privacy Policy">
          Privacy Policy
        </Link>
        <br />
        Copyright &copy;2024 Direct Buyers Inc, All Rights Reserved
      </BotFrame>
    </FooterFrame>
  )
}
