import React from "react";
import AddressForm from './address-search/address-search';
import styled from 'styled-components';
import mq from '../../styles/media-query';

const HeroFrame = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${props => props.bg}) no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  flex-direction: column;
  position: relative;
  
  * {
    position: relative;
    z-index: 1;
  }
  
  
  ${mq.sm`
    height: 360px;
    background-position: 50% 10%;
  `};
  
  ${mq.md`
    height: 450px;
  `};
  
  ${mq.lg`
    height: 550px;
  `};
  
  ${mq.xl`
    height: 600px;
  `};
`;

const BGScreen = styled.div`
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--brandSecondary);
    opacity: 0.4;
`;

const Header = styled.h1`
  color: var(--white);
  text-shadow: 0 1px 1px var(--brandSecondary), 0px 2px 30px var(--brandSecondary);
  font-weight: 700;
  margin: 0 15px;
  text-align: center;
  font-size: 30px;
  
  ${mq.sm`
     font-size: 30px;
  `};
  
  ${mq.md`
     font-size: 45px;
  `};
  
  ${mq.lg`
     font-size: 50px;
  `};
`;

const Subtitle = styled.p`
  color: var(--white);
  text-shadow: 2px 2px 3px rgba(0,0,0,.5);
  text-shadow: 0 1px 1px var(--brandSecondary), 0px 2px 16px var(--brandSecondary);  
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  padding: 20px;
  margin: 4px 0 16px;
  
  ${mq.xs`
    font-size: 18px;
    padding: 20px;
  `};
  
  ${mq.sm`
    font-size: 22px;
    width: 650px;
  `};
`;

const Hero = ({bg, header, subheader}) => {
    return (
        <HeroFrame bg={bg}>
            <Header>{ header /*We Are Ready to Buy Your House As-Is*/}</Header>
            <Subtitle>{ subheader /* Direct Buyers purchases thousands of homes and we can buy yours in just a few days.*/}</Subtitle>
            <AddressForm/>
            <BGScreen />
        </HeroFrame>
    );
}

export default Hero;